/*TIP*/
/*
  for better responsive design, set width of objects to a high % to fit mobile view
  then set a max-width with a fixed value to limit how big it will go
*/

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
/*******************************************************
UNIVERSAL PAGE STYLING
*******************************************************/

.thelogo
{
  width: 60%;
  max-width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  margin: auto;
}

.thelogo a {
  display: flex;
}

#logoPic
{
  width: 100%;
}

.page {
  width: 100%;
  background: #fdfdfd;
  font-family: 'Roboto Mono', monospace;
  color: blue;
  text-decoration: underline;
  font-size: 32px;
}

header {
  display: flex;
  position: fixed;
  width: 100%;
  height: 70px;
  background: black;
  color: #fff;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.headerCon {
  width: 80%;
  max-width: 1800px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}


#nav-container {
  position: fixed;
  height: 100vh;
  width: 100%;
  pointer-events: none;
  z-index: 1;
}



#nav-container .bg {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  height: calc(100% - 70px);
  visibility: hidden;
  opacity: 0;
  transition: .3s;
  background: #000;
}
#nav-container:focus-within .bg {
  visibility: visible;
  opacity: .6;
}
#nav-container * {
  visibility: visible;
}

.button {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  border: 0;
  background: transparent;
  border-radius: 0;
  height: 70px;
  width: 30px;
  cursor: pointer;
  pointer-events: auto;
  margin-left: 25px;
  touch-action: manipulation;
}
.icon-bar {
  display: block;
  width: 100%;
  height: 3px;
  background: #aaa;
  transition: .3s;
}
.icon-bar + .icon-bar {
  margin-top: 5px;
}

#nav-container:focus-within .button {
  pointer-events: none;
}
#nav-container:focus-within .icon-bar:nth-of-type(1) {
  transform: translate3d(0,8px,0) rotate(45deg);
}
#nav-container:focus-within .icon-bar:nth-of-type(2) {
  opacity: 0;
}
#nav-container:focus-within .icon-bar:nth-of-type(3) {
  transform: translate3d(0,-8px,0) rotate(-45deg);
}

#nav-content {
  margin-top: 70px;
  padding: 20px;
  width: 90%;
  max-width: 300px;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 70px);
  background-color: ghostwhite;
  pointer-events: auto;
  transform: translateX(-100%);
  transition: transform .3s;
  will-change: transform;
  contain: paint;
}

#nav-content ul {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style: none;
}

#nav-content li a {
  padding: 10px 5px;
  display: block;
  transition: color .1s;
}

#nav-content li a:hover {
  color: brown;
}

#nav-content li:not(.small) + .small {
  margin-top: auto;
}

#nav-container:focus-within #nav-content {
  transform: none;
}

#nav-content a {
  color: currentColor;
  transition: .2s ease-in-out;
}

.normal-menu {
  border-top: 70px;
  width: 465px;
  display: flex;
  align-items: center;
}

.normal-menu ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.normal-navs  {
  color: mediumslateblue;
  text-decoration: none;
}

.normal-navs:hover {
  color: brown;
}

.normal-menu li {
  display: inline;
}

/*when in mobile*/
@media (max-width: 1200px) {
  .normal-menu {
    display: none;
  }
}


/*when in desktop*/
@media (min-width: 1200px) {
  #nav-container {
    display: none;
  }

  header {
    justify-content: space-between;
  }

  .thelogo {
    margin: unset;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

* {
  padding: 0;
  margin: 0;
}



.navs {
  text-decoration: none;
  color: white;
}

.navs:hover {
  text-decoration: underline;
}

.onPage {
  text-decoration: underline;
  color: brown;
}

#btmBar {
  width: 100%;
  height: 10%;
  background-color: black;
  position: relative;
  bottom: 0;
}

.stickers-con {
  width: 90%;
  max-width: 900px;
  margin: auto;
  top: 8px;
  position: relative;
  text-align: center;
}

#stickers {
  width: 100%;
}

#creds {
  color: blue;
  width: 80%;
  margin: auto;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 8px;
  font-family: "Times New Roman";
}

/*******************************************************
INDEX PAGE STYLING
*******************************************************/
#b0 {
  background-color: black;
  width: 100%;
  height: 100vh;
}

.b0-container {
  width: 80%;
  margin: auto;
  max-width: 800px;
}

#win {
  width: 100%;
  margin: auto;
  max-width: 800px;
}

#win img {
  width: 100%;
}

#unicorns {
  overflow: hidden;
  width: 100%;
}

#unicornSlider {
  position: relative;
  width: 200%;
  animation: scroll 6s linear infinite;
}

#unicornSlider img {
  width: 15vw;
  max-width: 150px;

}

@keyframes scroll {
  0% {
    transform: translateX(calc(20% * 4));
  }
  100% {
    transform: translateX(calc(-20% * 4));
  }
}

#shazams {
  max-width: 800px;
}

#shazams img {
  width: 100%;
}

/*******************************************************
CATALOG PAGE STYLING
*******************************************************/
#b1 {
  margin: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

#tiles {
  top: 100px;
  width: 90%;
  max-width: 1400px;
  margin: auto;
  margin-bottom: 150px;
  text-align: center;
  position: relative;
}

#tiles img {
  width: 45%;
  max-width: 300px;
  padding: 5px;
}

/*******************************************************
CONTACT PAGE STYLING
*******************************************************/

#cheung {
  position: relative;
  padding-top: 200px;
  padding-bottom: 100px;
  font-size: 20px;
  width: 85%;
  max-width: 1200px;
  margin: auto;
}

.drag {
  width: 30%;
}

.smoke {
  width: 100%;
  max-width: 600px;
  margin: auto;
  text-align: center;
}

.smoke img {
  width: 100%;
  animation: glowing 3000ms infinite;
}

@keyframes glowing {
  0% { box-shadow: 0 0 -50px pink; }
  40% { box-shadow: 0 0 100px limegreen; }
  60% { box-shadow: 0 0 100px yellow; }
  100% { box-shadow: 0 0 -50px orange; }
}

.commissions {
  border: 1.5px solid black;
  text-align: right;
  padding: 50px;
  max-width: 500px;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
}

#sayo {
  width: 45%;
  max-width: 500px;
}

.psyloke {
  width: 45%;
  max-width: 700px;
}

.bounce-con {
  width: 200px;
  animation: x 5s linear infinite alternate;
}

.bounce {
  width: 200px;
  animation: y 2s linear infinite alternate;
}

@keyframes x {
  100% {
    transform: translateX(calc(70vw - 200px));
  }
}

@keyframes y {
  100% {
    transform: translateY(calc(100vh - 200px));
  }
}


.bounce2 {
  width: 70%;
  max-width: 580px;
}


/*******************************************************
ABOUT PAGE STYLING
*******************************************************/

#desc {
  width: 95%;
  margin: auto;
  position: relative;
  top: 100px;
  text-align: center;
  padding-bottom: 200px;
  max-width: 800px;
}

#vidz {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 50%;
}

#vidz iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#abt {
  font-size: 24px;
  height: 20%;
  border: 2px solid;
  padding: 20px;
  margin: 20px;

}

#gl {
  position: relative;
  width: 100%;
}

.ck {
  width: 100%;
}

.fl {
  width: 70%;

}

.disc {
  width: 5%;
}

.cherub {
  width: 10%;
}

#idiots img {
  width: 45%;
  max-width: 200px;
}
